export const referralTermsAndConditions = {
  en: {
    RTACtitle: "Niuli Referral Program Terms and Conditions",
    RTACsection1: "Eligibility:",
    RTACsection1point1:
      "The referral program is open to existing Niuli customers (“Referrers”) who have received an invitation to participate.",
    RTACsection1point2:
      "Referrals must be new customers who have not previously engaged with Niuli or made a booking (“Referred Customers”).",
    RTACsection2: "Referral Process:",
    RTACsection2point1:
      "Referrers must register for the referral program through the provided link (referral program section of their account page on niulihome.com) and supply all required information, including bank details, to receive commissions.",
    RTACsection2point2:
      "Upon registration, a unique referral code will be generated for the Referrer, which they can share with their friends, family, or network.",
    RTACsection3: "Referrer Commission:",
    RTACsection3point1:
      "Referrers will earn a 2% commission on the final price of successful purchases made by Referred Customers who use their referral code.",
    RTACsection3point2:
      "Commissions will only be paid after the Referred Customer’s purchase is confirmed, payment is completed, and the kitchen installation is finalized.",
    RTACsection3point3:
      "The commission eligibility process takes place after the completion of the sale, installation, and the customer's full payment has been received by the company. A payment request is then submitted to the company by the Referral Program management in accordance with the timelines specified in the parent company's policies.",
    RTACsection4: "Limits and Restrictions:",
    RTACsection4point1:
      "Each Referred Customer can only use one referral code.",
    RTACsection4point2:
      "A Referrer cannot earn a commission from the same Referred Customer multiple times.",
    RTACsection4point3:
      "Niuli reserves the right to cap the total earnings per Referrer if deemed necessary.",
    RTACsection4point4:
      "Niuli reserves the right to increase or decrease the commission rate at any time during the duration of this program.",
    RTACsection5: "Order Cancellations:",
    RTACsection5point1:
      "If a Referred Customer cancels their order after confirmation, the Referrer’s commission for that referral will be revoked.",
    RTACsection6: "Referral Code Sharing:",
    RTACsection6point1:
      "Referral codes can be shared via the referral program page on Niuli’s website or app.",
    RTACsection6point2:
      "Referral codes are intended for personal networks and cannot be distributed via paid advertising, spam, or any unethical means.",
    RTACsection6point3:
      "Note: It’s the responsibility of the Referred Customer to ensure the referral code is entered correctly during the booking process.",
    RTACsection6point4:
      "In the event that the Referrer is found to have violated any of the rules specified in the terms and conditions of this program or breached local regulations, the program management reserves the right to revoke and cancel the commission. The Referrer shall have no right to dispute this decision.",
    RTACsection7: "Offline Customers:",
    RTACsection7point1:
      "Referral codes can also be applied to offline bookings by providing the code during the design consultation booking process.",
    RTACsection7point2:
      "Note: It’s the responsibility of the Referred Customer to ensure the referral code is provided accurately.",
    RTACsection8: "Program Changes:",
    RTACsection8point1:
      "Niuli reserves the right to modify or terminate the referral program at any time without prior notice.",
    RTACsection8point2:
      "Any changes will be communicated through the official Niuli website or app.",
    RTACsection9: "Fraud Prevention:",
    RTACsection9point1:
      "Niuli reserves the right to investigate and disqualify any Referrer or Referred Customer found to be engaging in fraudulent activity or violating these terms.",
    RTACsection10: "Payment of Commissions:",
    RTACsection10point1:
      "Commissions will be transferred to the bank account provided by the Referrer during registration.",
    RTACsection10point2:
      "Niuli is not responsible for incorrect or incomplete bank information.",
    RTACsection10point3:
      "The commission will be transferred after completing all stages of the sale and installation, and once the company has received the full contract value. Niuli will then proceed to submit a request for the transfer of the due commission amount.",
    RTACsection11: "Miscellaneous :",
    RTACsection11point1:
      "By participating in the referral program, both Referrers and Referred Customers agree to these terms and conditions.",
    RTACsection11point2:
      "This program is also subject to the terms and conditions outlined in this agreement, as well as the commission policy of Ahmed Abdullah Hamad Al-Essa Company (the parent company). In case of any conflict, the policy of Ahmed Abdullah Al-Essa Company shall prevail. All parties will be provided with a copy of this policy.",
    RTACsection11point3:
      "This program is subject to the laws and regulations in force in the Kingdom of Saudi Arabia.",
  },
  ar: {
    RTACtitle: "شروط وأحكام برنامج الإحالة من نيولي",
    RTACsection1: "الأهلية:",
    RTACsection1point1:
      "برنامج الإحالة مفتوح للعملاء الحاليين لدى نيولي ('المُحيلون') الذين تلقوا دعوة للمشاركة.",
    RTACsection1point2:
      "يجب أن يكون العملاء المُحالون عملاء جددًا لم يسبق لهم التعامل مع نيولي أو إجراء حجز مسبق ('العملاء المُحالون').",
    RTACsection2: "عملية الإحالة:",
    RTACsection2point1:
      "يجب على المُحيلين التسجيل في برنامج الإحالة من خلال الرابط المقدم (قسم برنامج الإحالة في صفحة الحساب على موقع niulihome.com) وتقديم جميع المعلومات المطلوبة، بما في ذلك تفاصيل البنك، لاستلام العمولات.",
    RTACsection2point2:
      "بعد التسجيل، سيتم إنشاء رمز إحالة فريد للمُحيل يمكنه مشاركته مع الأصدقاء والعائلة وشبكته.",
    RTACsection3: "عمولة المُحيل:",
    RTACsection3point1:
      "يحصل المُحيلون على عمولة بنسبة 2% من السعر النهائي لعمليات الشراء الناجحة التي يقوم بها العملاء المُحالون باستخدام رمز الإحالة الخاص بهم.",
    RTACsection3point2:
      "تُدفع العمولات فقط بعد تأكيد عملية شراء العميل المُحال، واستكمال الدفع، والانتهاء من تركيب المطبخ.",
    RTACsection3point3:
      "يتم تأكيد أهلية العمولة بعد إتمام عملية البيع، والتركيب، واستلام الشركة للدفعة الكاملة من العميل. بعد ذلك، يتم تقديم طلب دفع العمولة من قبل إدارة برنامج الإحالة وفقًا للجداول الزمنية المحددة في سياسات الشركة الأم.",
    RTACsection4: "القيود والشروط:",
    RTACsection4point1: "يمكن لكل عميل مُحال استخدام رمز إحالة واحد فقط.",
    RTACsection4point2:
      "لا يمكن للمُحيل الحصول على عمولة من نفس العميل المُحال أكثر من مرة.",
    RTACsection4point3:
      "تحتفظ نيولي بحق وضع حد أقصى للأرباح الإجمالية لكل مُحيل إذا دعت الحاجة.",
    RTACsection4point4:
      "تحتفظ نيولي بحق زيادة أو تقليل نسبة العمولة في أي وقت خلال مدة البرنامج.",
    RTACsection5: "إلغاء الطلبات:",
    RTACsection5point1:
      "إذا قام العميل المُحال بإلغاء طلبه بعد التأكيد، فسيتم إلغاء العمولة المتعلقة بهذا الإحالة.",
    RTACsection6: "مشاركة رمز الإحالة:",
    RTACsection6point1:
      "يمكن مشاركة رموز الإحالة عبر صفحة برنامج الإحالة على موقع نيولي أو التطبيق.",
    RTACsection6point2:
      "تُستخدم رموز الإحالة للشبكات الشخصية فقط ولا يمكن توزيعها عبر الإعلانات المدفوعة، أو الرسائل المزعجة، أو أي وسائل غير أخلاقية.",
    RTACsection6point3:
      "ملاحظة: تقع على عاتق العميل المُحال مسؤولية التأكد من إدخال رمز الإحالة بشكل صحيح أثناء عملية الحجز.",
    RTACsection6point4:
      "في حال ثبوت مخالفة المُحيل لأي من القواعد الواردة في شروط وأحكام هذا البرنامج أو انتهاكه للوائح المحلية، تحتفظ إدارة البرنامج بحق إلغاء العمولة. ولا يحق للمُحيل الاعتراض على هذا القرار.",
    RTACsection7: "العملاء عن طريق المعرض:",
    RTACsection7point1:
      "يمكن أيضًا استخدام رموز الإحالة للحجوزات التي تتم عن طريق المعرض عبر تقديم الرمز أثناء عملية حجز استشارة التصميم.",
    RTACsection7point2:
      "ملاحظة: تقع على عاتق العميل المُحال مسؤولية التأكد من تقديم رمز الإحالة بشكل دقيق.",
    RTACsection8: "التغييرات في البرنامج:",
    RTACsection8point1:
      "تحتفظ نيولي بحق تعديل أو إنهاء برنامج الإحالة في أي وقت دون إشعار مسبق.",
    RTACsection8point2:
      "سيتم الإعلان عن أي تغييرات من خلال الموقع الرسمي أو التطبيق الخاص بنيولي.",
    RTACsection9: "منع الاحتيال:",
    RTACsection9point1:
      "تحتفظ نيولي بحق التحقيق وإلغاء أهلية أي مُحيل أو عميل مُحال يثبت تورطه في أنشطة احتيالية أو انتهاكه لهذه الشروط.",
    RTACsection10: "دفع العمولات:",
    RTACsection10point1:
      "تُحول العمولات إلى الحساب البنكي المقدم من المُحيل أثناء التسجيل.",
    RTACsection10point2:
      "نيولي غير مسؤولة عن المعلومات البنكية الخاطئة أو غير المكتملة.",
    RTACsection10point3:
      "تُحول العمولات بعد إتمام جميع مراحل البيع والتركيب، وبعد استلام الشركة لقيمة العقد الكاملة. عندها، تقوم نيولي بتقديم طلب لتحويل مبلغ العمولة المستحق.",
    RTACsection11: "متفرقات:",
    RTACsection11point1:
      "بمشاركتهم في برنامج الإحالة، يوافق كل من المُحيلين والعملاء المُحالين على هذه الشروط والأحكام.",
    RTACsection11point2:
      "يخضع هذا البرنامج أيضًا للشروط والأحكام الواردة في هذه الاتفاقية، بالإضافة إلى سياسة العمولات الخاصة بشركة أحمد عبدالله حمد العيسى (الشركة الأم). وفي حالة وجود أي تعارض، تسود سياسة شركة أحمد عبدالله حمد العيسى. سيتم توفير نسخة من هذه السياسة لجميع الأطراف.",
    RTACsection11point3:
      "يخضع هذا البرنامج للقوانين واللوائح المعمول بها في المملكة العربية السعودية.",
  },
};
